import Header from './components/Header'
import HeroSeven from './hero/HeroSeven'
import FeaturesOne from './features/FeaturesOne'
import StatsOne from './stats/StatsOne'
import AssetsTwo from './assets/AssetsTwo'
import CTAThree from './cta/CTAThree'
import FooterSeven from './footer/FooterSeven'
import { useEffect } from 'react'
import TestimonialsOne from "./testimonials/TestimonialsOne";

import PricingTwo from './pricing/PricingTwo'

const Main = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header />
    <HeroSeven />
    <br />
    <FeaturesOne />
    <StatsOne />
    <AssetsTwo />
    {/* <TestimonialsOne /> */}
    {/* <PricingTwo /> */}
    <CTAThree />
    <FooterSeven />
    </>
  )
}

export default Main